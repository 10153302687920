/* eslint-disable class-methods-use-this */
/* globals page, ga, _values */

import Axios from 'axios';
import {
    cacheAdapterEnhancer,
    throttleAdapterEnhancer,
} from 'axios-extensions';
import Page from './transitions/helpers/default-page';

// Import the transitions used by the Router in this application
import defaultTransition from './transitions/default';

/**
 * The Router class handles the page routing by including the Routes logic from the
 * routes.js file. The Router is dependend on the page.js and axios libraries,
 * which have to be including in the project bootstrap to work.
 */
export default class Router {
    constructor(options) {
        // Configure
        this.app = options.instance || null;
        this.prefetch = options.prefetch || false;
        this.inTransition = false;
        this.transition = 'default';

        this.currentSection = options.section || 'page-home';

        // Initialize
        this.init();
    }

    init() {
        const routes = new Map();
        routes.set('/', this.startTransition.bind(this));
        routes.set('*', this.startTransition.bind(this));

        // Register page routes
        routes.forEach((routeHandler, path) => {
            page(path, routeHandler);
        });

        // Start page.js, without first dispatch
        // This without prevent page.js to use transitions on first load
        page({ dispatch: false, popstate: true });

        // window.onpopstate = () => {
        //     window.location.reload();
        // };

        // Enhance the original axios adapter with throttle and cache enhancer
        this.http = Axios.create({
            baseURL: '/',
            headers: {
                'Cache-Control': 'no-cache',
                'X-Requested-With': 'XMLHttpRequest',
            },
            adapter: throttleAdapterEnhancer(
                cacheAdapterEnhancer(Axios.defaults.adapter),
            ),
        });

        // Listen for events to prefetch urls
        if (this.prefetch) {
            document.body.addEventListener(
                'mouseover',
                this.onLinkEnter.bind(this),
            );
            document.body.addEventListener(
                'touchstart',
                this.onLinkEnter.bind(this),
            );
        }
    }

    /**
     * Callback for the mousehover/touchstart
     *
     * @memberOf Barba.Prefetch
     * @private
     * @param  {Object} evt
     */
    onLinkEnter(evt) {
        let el = evt.target;

        // Climb up the parent tree until we find a link.
        while (el && !el.href) {
            el = el.parentNode;
        }

        // Ignore if we run out of elements or the found link has:
        // 1) A class indicating we should ignore it
        // 2) A target attribute, indicating it is not suitable for prefetching in the router context
        if (!el || el.classList.contains('ignore-fetch') || el.target) {
            return;
        }

        // Cache it
        const url = el.href;
        this.http.get(url, { cache: true });
    }

    /**
     * Set the application transition.
     *
     * @param {string} transition
     * @return {void}
     */
    setTransition(transition) {
        // Create a flat array from the transitions object
        const strings = _values(this.transitions);

        // Set the transition if it exists or if it's default
        if (strings.indexOf(transition) || transition === 'default') {
            this.transition = transition;
            return;
        }

        // Alert if the transition does not exist
        console.log(
            `Transition "${transition}" does not exist. Be sure to set the correct transition or use "default".`,
        );
    }

    /**
     * Called when the page transition is going to start.
     *
     * @return {void}
     */
    pageTransitionStart() {
        console.log('Calling the router completion handler');
    }

    /**
     * Starts the page transition.
     *
     * @param {Object}   ctx
     * @param {Function} next
     * @return {void}
     */
    startTransition(ctx, next) {
        // Leave if we are already in transition
        if (this.inTransition) {
            return;
        }

        // Set inTransition state
        this.inTransition = true;

        EventBus.$emit('page-transition-leave');

        window.requestAnimationFrame(() => {
            this.http
                .get(ctx.path)
                .then((response) => {
                    this.destroyPage();

                    switch (this.transition) {
                    default:
                        defaultTransition(response, ctx, this);
                        break;
                    }
                })
                .catch(this.onTransitionError);
        });
    }

    /**
     * Called when the page transition is completed. Normally, everything
     * should already be taken care of. However, this is the place
     * where you can do any additional page transition cleaning.
     *
     * @return {void}
     */
    pageTransitionComplete() {
        // Reset the page transition
        this.transition = 'default';

        // Make sure the new content is added, and the 'ga()' method is available.
        if (typeof ga === 'function') {
            ga('set', {
                page: window.location.pathname,
                title: document.title,
            });
            ga('send', 'pageview');
        }

        // Emit completion event
        // !! Do not remove
        EventBus.$emit('page-transition-enter-complete');
        this.transition = 'default';
        this.inTransition = false;
    }

    /**
     * Raised when there was an error fetching the ajax data using axios.
     *
     * @param  {String} error
     * @return {void}
     */
    onTransitionError(error) {
        console.log(error);
    }

    /**
     * (Re)build the Vue instance by using the Page component
     *
     * @param  {DOMElement} el
     * @return {void}
     */
    buildPage(el) {
        const page = new Page({ el, parent: this.app }); // eslint-disable-line no-unused-vars

        return page;
    }

    /**
     * Destroys the current Vue instances on the page,
     * usefull before starting a transition.
     *
     * @return {void}
     */
    destroyPage() {
        for (let index = 0; index < this.app.$children.length; index++) {
            const child = this.app.$children[index];
            this.destroyChildComponent(child);
        }
    }

    destroyChildComponent(component) {
        for (let index = 0; index < component.$children.length; index++) {
            const child = component.$children[index];
            this.destroyChildComponent(child);
        }

        if (component === undefined) {
            return;
        }

        if (component.$options.name === 'showreel-modal') {
            return;
        }

        // Check if the child is a Vue vm instance
        if (typeof component.$destroy !== 'undefined') {
            component.$destroy(true);
        }
    }
}
