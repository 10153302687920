/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { gsap, Power1 } from 'gsap/all';

/**
 * Do the default transition logic.
 *
 * @param  {JSON} response
 *
 * @return {void}
 */
export default (response, ctx, router) => {
    // Query the current situation.
    const app = document.getElementById('page-wrapper');
    const current = document.getElementById('page-container');

    // Inject the new page and query it as the ajax template.
    const nextFragment = document.createRange().createContextualFragment(response.data);
    app.appendChild(nextFragment);
    const ajaxTemplate = document.querySelector('.ajax-template');

    const onBuildComplete = () => {};

    // Leave timeline onComplete callback.
    const onLeaveComplete = () => {
        current.parentNode.removeChild(current);
        ajaxTemplate.classList.remove('ajax-template');

        // Rebuild Vue
        router.buildPage(app, onBuildComplete);
    };

    // The actual leave timeline.
    const leaveTl = gsap.timeline({
        paused: false,
        onComplete: onLeaveComplete,
    });
    leaveTl.to(
        '.transition-curtain',
        0.7,
        { scaleY: 1, transformOrigin: 'bottom center', ease: Power1.easeOut },
        0,
    );

    // Called when the new page has been build by Vue.
    // This is the moment we prepare the new page and run a enter timeline.
    EventBus.$once('page-transition-enter', () => {
        window.scrollTo(0, 0);

        // Switch section.
        document.title = ajaxTemplate.dataset.title || document.title;
        document.body.classList.remove(router.currentSection);
        document.body.classList.add(ajaxTemplate.dataset.namespace);
        router.currentSection = ajaxTemplate.dataset.namespace;
        router.app.showFooter = ajaxTemplate.dataset.showFooter;
        router.app.darkHeader = ajaxTemplate.dataset.darkHeader;
        router.app.showNavigation = false;

        window.requestAnimationFrame(() => {
            Vue.nextTick(() => {
                const onEnterComplete = () => {
                    router.pageTransitionComplete();
                };

                const enterTl = gsap.timeline({
                    paused: true,
                    onComplete: onEnterComplete,
                });
                enterTl.to(
                    '.transition-curtain',
                    0.7,
                    {
                        scaleY: 0,
                        transformOrigin: 'top center',
                        ease: Power1.easeIn,
                    },
                    0.4,
                );
                enterTl.play();
            });
        });
    });
};
