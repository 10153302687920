/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import 'lazysizes';
import { gsap } from 'gsap';
import { debounce } from 'lodash';
import Rellax from 'rellax';
import Vue from 'vue';
import Router from './router';

require('./bootstrap');

const App = new Vue({
    el: '#app',
    delimiters: ['${', '}'],

    data() {
        return {
            router: {
                currentSection: null,
                instance: null,
                prefetch: true,
            },
            // router: null,
            // isMobile: false,
            // isTablet: false,
            // scrollbar: null,
            showNavigation: false,
            home: true,
            offGrid: false,
            marine: false,
            // darkHeader: '1',
            // showFooter: '1'
        };
    },

    watch: {
        isMobile(value) {
            EventBus.$emit('is-mobile-changed');
        },
        isTablet(value) {
            EventBus.$emit('is-tablet-changed');
        },
        showNavigation(visibile) {
            EventBus.$emit('navigation-state-changed', visibile);
        },
    },

    mounted() {
        window.addEventListener('resize', this.onWindowResize);
    },

    methods: {
        /**
         * Raised when the client window is resizing.
         *
         * @return {Void}
         */
        onWindowResize: debounce(() => {
            EventBus.$emit('window-resize');
        }, 150),

        detectMobile() {
            this.isMobile = window.innerWidth <= 768;
            this.isTablet = window.innerWidth <= 1024;
            console.log(`Is Mobile: ${this.isMobile}`);
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    },
});
